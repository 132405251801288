import {Observable} from "rxjs";

import {
  CalendarContact,
  CalendarEvent, CalendarGroup,
  CalendarMeInfo,
  CalendarSchedule,
  CalendarUser
} from "../../models";
import {CalendarServerType} from "../../models/calendar-server-type.enum";

export abstract class CalendarsService {

  protected constructor(
    public type: CalendarServerType
  ) {}

  abstract getIdentifierFromContact(contact: CalendarContact): string;
  abstract getImageUrl(identifier: string): Observable<string>;
  abstract getMeInfo(): Observable<CalendarMeInfo>;
  abstract availableMeetingProviders(): Observable<string[]>;
  abstract findContact(input: string): Observable<CalendarContact[]>;
  abstract getContactsFromGroup(id: string): Observable<CalendarContact[]>;
  abstract findContactGroup(input: string): Observable<CalendarGroup[]>;
  abstract deleteEvent(id: string) : Observable<boolean>;
  abstract finishEvent(eventId: string): Observable<{
    id: string, start: Date,
    end: Date
  }>;
  abstract patchEvent(
    visibility: "public" | "private",
    status: { key: string, value: string },
    eventId: string,
    subject: string,
    text: string,
    start: Date,
    end: Date,
    roomName: string,
    roomEmail: string,
    attendees: {
      isResource: boolean;
      address: string;
      name: string;
      type: 'required' | 'optional';
    }[],
    onlineMeeting: {
      onlineMeetingProvider: string;
    }
  ): Observable<any>;
  abstract createEvent(
    visibility: "public" | "private",
    status: { key: string, value: string },
    subject: string,
    text: string,
    start: Date,
    end: Date,
    roomName: string,
    roomEmail: string,
    attendees: {
      address: string;
      name: string;
      type: 'required' | 'optional';
    }[],
    onlineMeeting?: {
      onlineMeetingProvider: string;
    },
    webexMeeting?: {
      body: any,
      url: string,
      code: string,
      password: string
    }
  ): Observable<{ id: string; response: string, iCalId: string }>;



  //abstract getEvents(email: string, startFrom: Date, endFrom: Date): Observable<{ value: CalendarEvent[] }>;
  abstract getCalendarEvents(from: Date, to: Date, email: string, settings?: any): Observable<{ events: CalendarEvent[]; nextLink: string}>;
  abstract getCollegueInfo(identifier: string): Observable<CalendarUser>;
  abstract getScheduleForEmails(
    emails: string[],
    fromDate: Date,
    toDate: Date
  ) : Observable<CalendarSchedule[]>;

  protected getChunks(myArray: any[], chunk_size: number) {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray: any[] = [];
    let chunk: any[] = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      chunk = myArray.slice(index, index + chunk_size);
      tempArray.push(chunk);
    }

    return tempArray;
  }

  protected onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }
}
